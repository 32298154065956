<template>
  <div class="new__table new__table_result">
    <div class="scheduler">
      <div class="schedule">
        <div class="crm-schedule-box d-inline-flex w-100">
          <table class="table table-bordered nurseOffice_table crm-schedule-table table-bordered">
            <thead>
              <tr>
                <th>{{ $t('message.drug') }}</th>
                <th v-for="date in dateList" :key="date.val + '-datess'">
                  {{ date.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="patientIndicator in list" :key="patientIndicator.id">
                <td class="left_likarstovas">
                  {{  patientIndicator.patientIndicatorName ?  patientIndicator.patientIndicatorName.name : '' }}</td>
                <td v-for="date in dateList" :key="date.val + '-dat  '" class="dataDay">
                  <template v-if="hasLek(date,patientIndicator)">
                       <template v-if="patientIndicator.is_edit == 1">
                        <template v-for="i in patientIndicator.count" >
                            <div class="modalCells is_edit" :class="'height'+patientIndicator.count" :key="i + '-count'" @click="openPatient(date, patientIndicator, i)">{{ showPatient(date, patientIndicator, i) }}</div>
                        </template>
                    </template>
                    <template  v-else>
                        <template v-for="i in patientIndicator.count" >
                            <div class="modalCells" :class="'height'+patientIndicator.count" :key="i + '-count'" @click="openPatient(date, patientIndicator, i)">{{ showPatient(date, patientIndicator, i) }}</div>
                        </template>
                    </template>
                  </template>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <el-dialog
        class="modal_lea xModal"
        :title="$t('message.condition_patient')"
        width="30%"
        :visible.sync="dialogTableVisible2"
        @closed="closeDialog()"  @opened="$refs['addResult'].afterOpened()"
      >
      <AddResult :selected="selectedModel" ref="addResult" @c-close="closeDialog" />
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import AddResult from "./components/addResult";
  import drawer from "@/utils/mixins/drawer";
  import {
    mapActions,
    mapGetters
  } from 'vuex';
  import list from "@/utils/mixins/list";
  export default {
    mixins: [list, drawer],
    // props: ['selected'],
    name: "nurseOffice",
    components: {
      AddResult,
    },
    data() {
      return {
        dialogTableVisible2: false,
        loadingData: false,
        selectedModel: {},
        filterForm: {},
        drawer: {
          update: {
            name: "update",
            status: false,
            component: 'componentDrawerUpdate'
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        indexList: 'patientIndicatorIndeces/list',
        list: 'patientIndicators/list',
        columns: "patientIndicators/columns",
        dateList: 'patientIndicators/dateList',
        pagination: "patientIndicators/pagination",
        filter: "patientIndicators/filter",
        sort: "patientIndicators/sort",
      }),
      actions: function () {
        return ['edit', 'delete'];
      }
    },
    async mounted() {
      $(".new__table_result .crm-schedule-table tbody").scroll(function (e) {
        //detect a scroll event on the tbody
        $(".new__table_result .crm-schedule-table thead").css(
          "left",
          -$(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the thead relative to the body scrolling
        $(".new__table_result .crm-schedule-table thead th:nth-child(1)").css(
          "left",
          $(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the first cell of the header
        $(".new__table_result .crm-schedule-table tbody td:nth-child(1)").css(
          "left",
          $(".new__table_result .crm-schedule-table tbody").scrollLeft()
        ); //fix the first column of tdbody
      });
    },
    methods: {
      ...mapActions({
        updateList: 'patientIndicators/index',
        updateSort: "patientIndicators/updateSort",
        updateFilter: "patientIndicators/updateFilter",
         showNumber: 'patientIndicators/showNumber',
        updateColumn: "patientIndicators/updateColumn",
        updatePagination: "patientIndicators/updatePagination",
        editModel: 'patientIndicators/show',
        empty: 'patientIndicators/empty',
        delete: 'patientIndicators/destroy',
        refreshData: 'patientIndicators/refreshData',
      }),
      hasLek(date, dailyT) {
        if (dailyT.end_date) {
          let days = dailyT.end_date;
          if (_.isArray(days) && days.includes(date.val)) {
            return true;
            0
          }
        }
      },
      fetchData() {
      },
      refresh() {
        this.refreshData()
          .then(res => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch(err => {

          })
      },
      openPatient(date,dt,i){
        if (dt.patientIndicatorIndeces) {
          
          let days = dt.patientIndicatorIndeces;
            
              if (_.isArray(days)) {
                  let item = _.find(days, 
                  function (o) {  return (o.day == date.val && o.time == i)});
                  // console.log(item);
                  if (item) {
                      this.dialogTableVisible2 = true;
                      this.selectedModel = item;
                  }     
              }
          }
      },
      showPatient(date,dt,i){
        if (dt.patientIndicatorIndeces) {
          
          let days = dt.patientIndicatorIndeces;
            
              if (_.isArray(days)) {
                  let item = _.find(days, 
                  function (o) {  return (o.day == date.val && o.time == i)});
                  if (item) {
                    return item.comment
                  }  
              }
          }
      },
      closeDialog(){
        this.dialogTableVisible2 = false
      },
      emptyModel() {
        this.empty()
      },
    }
  };
</script>

<style lang="css">
.new__table .scheduler table.crm-schedule-table>tbody tr td:nth-child(1){
      flex-direction: column;
}
.modalCells{
    flex: 1;
}
.is_edit{
  background: #f56c6c8a !important
}
.ta_button{
 width: 100%;
 display: flex;
 justify-content: space-around;
 margin-bottom: 3px;
}
.ta_button .el-button{
    width: 48%;
    margin: 0px;
}
.new__table.new__table_2 tbody::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}
.new__table.new__table_2 tbody::-webkit-scrollbar-track {
  background: #f1f1f1;        /* color of the tracking area */
}
.new__table.new__table_2 tbody::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f1f1f1;  /* creates padding around scroll thumb */
}
body::-webkit-scrollbar {
  width: 15px;               /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: #fff;        /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #fff;  /* creates padding around scroll thumb */
}
</style>